import request from '@/utils/request'

export function getPageList(data) {
  return request({
    url: 'campAppoint/getPageList',
    method: 'post',
    data,
  })
}

export function del(data) {
  return request({
    url: 'campAppoint/delete',
    method: 'post',
    data,
  })
}

export function save(data) {
  return request({
    url: 'campAppoint/save',
    method: 'post',
    data,
  })
}

